
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: MyFont;
  scroll-behavior: smooth;

}
.container {
  max-width: 100%;
  margin-right: 0;
  margin-left: 0;
  background-color: #e8e4c0;
  position: relative;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(59,154,225,0.5);; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(57,110,176,0.5); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(57,110,176,0.5); 
}
@font-face {
  font-family: MyFont;
  src: url(./Font/FiraSansCondensed-ExtraLight.ttf);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(15 55 108);
  -webkit-box-shadow: 0 0 0px 1000px rgb(59, 154, 225, 0.4) inset;
  -webkit-backdrop-filter: blur(20px);
  transition: background-color 5000s ease-in-out 0s;
}